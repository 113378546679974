import { type CurrencyCode } from "@doitintl/cmp-models";
import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type AccountManagerModel } from "./AccountManager";
import { type MasterPayerAccountsModel, type OneTimeServiceType } from "./App";
import { type AssetModel } from "./Asset";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";
import { type TierModel } from "./Tier";
import { type VendorContractModel } from "./VendorContract";

export type UpdatedBy = {
  email: string;
  name: string | null;
};

type ContractUpdates = {
  type: string;
  timestamp: Timestamp;
};

/**
 * Support levels for Google cloud contracts.
 * It only has one for now, but the architecture around support level has been designed to be extensible.
 */
export enum GooglePartnerSupportLevel {
  PARTNER_PREMIUM = "Partner-Led Premium Support",
}

@collection("contracts")
export class ContractModel {
  accountManager!: Reference<AccountManagerModel> | null;

  active!: boolean;

  assets?: Reference<AssetModel>[] | null;

  plpsPercent?: number;

  commitmentPeriods?: CommitmentPeriod[];

  commitmentRollover?: boolean;

  contractFile!: ContractFile;

  credits?: ContractCredits;

  customer!: Reference<CustomerModel>;

  discount!: number;

  discountEndDate?: Timestamp;

  discountPeriods?: DiscountPeriod[];

  endDate!: Timestamp | null;

  entity!: Reference<EntityModel> | null;

  estimatedValue!: number;

  isCommitment!: boolean;

  isSoftCommitment!: boolean;

  isRenewal!: boolean;

  notes!: string;

  partnerMargin!: number;

  properties!: Properties | null;

  purchaseOrder!: string;

  startDate!: Timestamp;

  terminated!: boolean;

  timeCreated!: Timestamp;

  timestamp!: Timestamp;

  type!: ContractModelType;

  updatedBy?: UpdatedBy | string;

  vendorContract?: Reference<VendorContractModel>;

  subCollections?: {
    contractUpdates: ContractUpdates;
  };

  gcpContract?: Reference<ContractModel>[];

  isAdvantage?: boolean;

  pointOfSale?: PointOfSaleType;

  // DoiT Cloud Navigator/Solve
  tier?: Reference<TierModel>;

  commitmentMonths?: number; // if undefined, monthly automatic renewal

  currency?: CurrencyCode;

  paymentTerm?: SubscriptionPaymentTerm;

  chargePerTerm?: number;

  monthlyFlatRate?: number; // Solve only

  // DoiT Cloud Intelligence
  minimumServiceFee?: number;

  cloudSpendPercentage?: number;
}

export interface CommitmentPeriod {
  endDate: Timestamp;
  startDate: Timestamp;
  value: number;
  discount?: number;
}

export interface DiscountPeriod {
  startDate: Timestamp;
  endDate: Timestamp | null;
  discount: number;
}

export interface ContractFile {
  id: string;
  name: string;
  parentId: null | string;
  storage: null | string;
  url: string;
}

export interface ContractSupport {
  discount: number | null;
  flatRate: number | null;
  lastUpdateTime: Timestamp | null;
  mpaRef: Reference<MasterPayerAccountsModel> | null;
}

export type SkuData = {
  label: string;
  monthlyListPrice: number;
  googleSku: string;
};

export type LookerSku = {
  skuName: SkuData;
  months: number;
  quantity: number;
  monthlySalesPrice: number;
  startDate?: Timestamp;
};

export type GCPContractSupport = {
  originalSupportTier: string;
  updatedAt: Timestamp;
};

export interface Properties {
  awsFlexSaveOverwrite?: number;
  discountPreemptible?: boolean;
  domains?: string[];
  flexRI?: number;
  freeUntil?: Timestamp;
  gcpFlexSaveOverwrite?: number | string;
  gcpSupport?: GCPContractSupport; // optional property updated by cron job once a day
  pricelistSheet?: ContractFile | null;
  rebaseModifier?: number;
  specialDiscount?: number | null;
  subscriptionFilter?: boolean[];
  supportPlan?: string;
  support?: Record<number, ContractSupport> | null;
  userEmail?: string;

  // looker
  skus?: LookerSku[];
  salesProcess?: string;
  invoiceFrequency?: number;
  contractDuration: number;

  // DoiT One-Time Service Fee
  typeContext?: Reference<OneTimeServiceType>;
  estimatedFunding?: number;
}

export const ContractTypeBettercloud = "bettercloud";
export const ContractTypeGSuite = "g-suite";
export const ContractTypeAws = "amazon-web-services";
export const ContractTypeAwsPpaCloudfront = "amazon-web-services-ppa-cloudfront";
export const ContractTypeMicrosoftAzure = "microsoft-azure";
export const ContractTypeGoogleCloud = "google-cloud";
export const ContractTypeDoitCloudIntelligence = "doit-cloud-intelligence";
export const ContractTypeLooker = "looker";
export const ContractTypeDoitCloudSolve = "solve";
export const ContractTypeOffice365 = "office-365";
export const ContractTypeGoogleCloudPartnerLedPremiumSupport = "google-cloud-partner-led-premium-support";
export const ContractTypeGoogleGeolocationServices = "google-geolocation-services";
export const ContractTypeDoitCloudNavigator = "navigator";
export const ContractTypeZendesk = "zendesk";
export const ContractTypeSecurityCommandCenter = "security-command-center";
export const ContractTypeRecaptchaEnterprise = "recaptcha-enterprise";
export const ContractTypeChronicleSecurity = "chronicle-security";
export const ContractTypeApigee = "apigee";
export const ContractTypeSaasConsoleAws = "saas-console-aws";
export const ContractTypeDoitOneTimeServiceFee = "doit-one-time-service-fee";
export const ContractTypeGoogleCloudStandalone = "google-cloud-standalone";
export const ContractTypeSaasConsoleGcp = "saas-console-gcp";
export const ContractTypeGoogleCloudMandiant = "google-cloud-mandiant";
export const ContractTypeAwsPpaS3 = "amazon-web-services-ppa-s3";
export const ContractTypeAwsStandalone = "amazon-web-services-standalone";
export const ContractTypeGoogleCloudPremiumSupport = "google-cloud-premium-support";
export const ContractTypeGoogleCloudAnthos = "google-cloud-anthos";
export const ContractTypeVirusTotal = "virus-total";
export const ContractTypeBeyondcorpEnterprise = "beyondCorp-enterprise";
export const ContractTypeAwsPpaDto = "amazon-web-services-ppa-dto";
export const ContractTypeGoogleCloudPpaWebRisk = "google-cloud-ppa-web-risk";
export const ContractTypeLookerStudioPro = "looker-studio-pro";
export const ContractTypeAwsPpaPinpoint = "amazon-web-services-ppa-pinpoint";
export const ContractTypeAwsPpaSes = "amazon-web-services-ppa-ses";
export const ContractTypeAwsPpaShield = "amazon-web-services-ppa-shield";
export const ContractTypeAwsPpaEc2 = "amazon-web-services-ppa-ec2";
export const ContractTypeDoiTSinglePurchase = "doit-cloud-intelligence-sp";

export type ContractModelType =
  | typeof ContractTypeAws
  | typeof ContractTypeSaasConsoleAws
  | typeof ContractTypeAwsStandalone
  | typeof ContractTypeAwsPpaCloudfront
  | typeof ContractTypeBettercloud
  | typeof ContractTypeDoitCloudNavigator
  | typeof ContractTypeDoitCloudSolve
  | typeof ContractTypeGoogleCloudPartnerLedPremiumSupport
  | typeof ContractTypeSaasConsoleGcp
  | typeof ContractTypeGoogleCloud
  | typeof ContractTypeGoogleCloudStandalone
  | typeof ContractTypeGSuite
  | typeof ContractTypeLooker
  | typeof ContractTypeMicrosoftAzure
  | typeof ContractTypeOffice365
  | typeof ContractTypeZendesk
  | typeof ContractTypeGoogleGeolocationServices
  | typeof ContractTypeDoitOneTimeServiceFee
  | typeof ContractTypeDoitCloudIntelligence
  | typeof ContractTypeApigee
  | typeof ContractTypeAwsPpaS3
  | typeof ContractTypeBeyondcorpEnterprise
  | typeof ContractTypeChronicleSecurity
  | typeof ContractTypeGoogleCloudAnthos
  | typeof ContractTypeGoogleCloudPremiumSupport
  | typeof ContractTypeGoogleCloudPpaWebRisk
  | typeof ContractTypeLookerStudioPro
  | typeof ContractTypeGoogleCloudMandiant
  | typeof ContractTypeSecurityCommandCenter
  | typeof ContractTypeVirusTotal
  | typeof ContractTypeRecaptchaEnterprise
  | typeof ContractTypeAwsPpaPinpoint
  | typeof ContractTypeAwsPpaDto
  | typeof ContractTypeAwsPpaShield
  | typeof ContractTypeAwsPpaSes
  | typeof ContractTypeAwsPpaEc2
  | typeof ContractTypeDoiTSinglePurchase;

export type ContractCredits = Record<string, Credit>;

type Credit = {
  amount: number;
  description: string;
};

export type SubscriptionPaymentTerm = "annual" | "monthly";

export type ProductTypes = {
  label: string;
  value: string;
}[];

export type PointOfSaleType = "doit" | "aws-marketplace" | "gcp-marketplace";
