import { useCallback, useMemo, useState } from "react";

import {
  type AssetType,
  type BillingToolsActionType,
  type ContractModelType,
  type IssueCategoryType,
} from "@doitintl/cmp-models";
import { DoitRole } from "@doitintl/cmp-models";
import { Box, Checkbox, FormControlLabel, MenuItem, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { CustomerPicker } from "../../../../Components/CustomerPicker/CustomerPicker";
import LoadingButton from "../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useUserContext } from "../../../../Context/UserContext";
import { actionTypeToName, assetTypeName, issueCategoryTypeToName } from "../../../../utils/common";
import { useInvoices } from "../DraftInvoices/hooks";
import { issuableInvoiceTypes } from "../DraftInvoices/utils";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const apiPathByAction = {
  issue: "issue-customer-invoices",
  recalculate: "recalculate-customer",
  "copy-billing-tables": "copy-aws-billing-tables-to-dev",
};

const issueCloudOptions: ContractModelType[] = [
  "doit-cloud-intelligence",
  "navigator",
  "solve",
  "doit-one-time-service-fee",
  "amazon-web-services",
  "google-cloud",
  "looker",
  "office-365",
  "g-suite",
  "doit-cloud-intelligence-sp",
];

const recalculateCopyTableCloudOptions: ContractModelType[] = ["amazon-web-services"];

const cloudOptionsMap: Record<string, ContractModelType[]> = {
  recalculate: recalculateCopyTableCloudOptions,
  issue: issueCloudOptions,
  "copy-billing-tables": recalculateCopyTableCloudOptions,
};

const actionOptions: BillingToolsActionType[] = ["copy-billing-tables", "recalculate", "issue"];

const categoryOptions: IssueCategoryType[] = [
  "error_gap_in_billing_pipeline",
  "error_gap_in_invoicing_pipeline",
  "customer_configuration_error",
  "customer_requested_change",
  "other",
];

const actionPermissions: Record<BillingToolsActionType, DoitRole[]> = {
  "copy-billing-tables": [
    DoitRole.InvoicingCopyBillingTablesUser,
    DoitRole.InvoicingToolsUser,
    DoitRole.InvoicingAdmin,
  ],
  recalculate: [DoitRole.InvoicingRecalculationUser, DoitRole.InvoicingAdmin],
  issue: [DoitRole.InvoicingToolsUser, DoitRole.InvoicingAdmin],
};

const maxDate = DateTime.utc().endOf("month");
const minDate = DateTime.fromObject({ year: 2019, month: 3, day: 1 });
const lastMonth = DateTime.utc().startOf("month").minus({ months: 1 });

const IssueForm = ({ loadJobs }: { loadJobs: () => Promise<void> }) => {
  const api = useApiContext();
  const showError = useErrorSnackbar(7);
  const showSuccessSnackbar = useSuccessSnackbar();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });

  const hasPermission = (permissions: DoitRole[]): boolean =>
    permissions.some((role) => userRoles.permissions.has(role));

  const actionOptionsWithPermissions = actionOptions.filter((action) => hasPermission(actionPermissions[action]));

  const [selectedCustomer, setSelectedCustomer] = useState<{ id: string; name: string } | null>(null);
  const [formData, setFormData] = useState<{
    invoiceMonth: DateTime<true>;
    cloud?: AssetType;
    reason?: string;
    category?: IssueCategoryType;
    isReissue?: boolean;
  }>({
    invoiceMonth: lastMonth,
    isReissue: false,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [action, setAction] = useState<BillingToolsActionType>("issue");

  const { invoices, invoicesLoading, loadInvoices } = useInvoices(selectedCustomer?.id, formData.invoiceMonth);

  const issuedInvoicesByCloud = useMemo(
    () => invoices.filter((invoice) => invoice.issuedAt && invoice.type === formData.cloud),
    [invoices, formData.cloud]
  );

  const cancelInvoices = useCallback(async () => {
    const entityIds = uniq(issuedInvoicesByCloud.map((invoice) => invoice.entity.id));

    const cancelPayload = {
      entityIds,
      month: formData.invoiceMonth.toFormat("yyyy-MM"),
      cancellationReason: `Auto-cancelled for issue: ${formData.reason}`,
      types: [formData.cloud],
    };

    setIsSubmitting(true);
    await api
      .post("/v1/invoicing/cancel-issued-invoices", cancelPayload)
      .then(() => {
        showSuccessSnackbar("Successfully cancelled existing invoices");
      })
      .catch((err) => {
        showError(err.response?.data?.errors);
      })
      .finally(() => {
        loadInvoices();
      });
  }, [
    api,
    loadInvoices,
    showError,
    showSuccessSnackbar,
    issuedInvoicesByCloud,
    formData.invoiceMonth,
    formData.reason,
    formData.cloud,
  ]);

  const handleAction = useCallback(
    async (action: BillingToolsActionType) => {
      const invoiceMonthFormatted =
        action !== "copy-billing-tables" ? formData.invoiceMonth.toFormat("yyyy-MM-01") : undefined;

      if (action === "issue" && issuableInvoiceTypes.includes(formData.cloud as ContractModelType)) {
        try {
          if (issuedInvoicesByCloud.length > 0) {
            await cancelInvoices();
          }
        } catch (error: unknown) {
          const err = error as { response?: { data?: { errors?: string } } };
          showError(err.response?.data?.errors);
          setIsSubmitting(false);
          setOpenConfirmationDialog(false);
          return;
        }
      }

      const issuePayload = {
        customerId: selectedCustomer?.id,
        invoiceMonth: invoiceMonthFormatted,
        reason: formData.reason,
        assetType: formData.cloud,
        category: formData.category,
        isReissue: formData.isReissue,
      };

      setIsSubmitting(true);
      await api
        .post(`/v1/invoicing/${apiPathByAction[action]}`, issuePayload)
        .then(() => {
          showSuccessSnackbar("Success");
        })
        .catch((err) => {
          showError(`Error: ${err.response?.data?.error}`);
        })
        .finally(() => {
          setIsSubmitting(false);
          setOpenConfirmationDialog(false);
          loadJobs();
        });
    },
    [
      api,
      formData.category,
      formData.cloud,
      formData.invoiceMonth,
      formData.isReissue,
      formData.reason,
      issuedInvoicesByCloud,
      loadJobs,
      selectedCustomer?.id,
      showError,
      showSuccessSnackbar,
      cancelInvoices,
    ]
  );

  const formIncomplete = useMemo(
    () => !selectedCustomer || !formData.cloud || !formData.reason || isSubmitting,
    [formData.cloud, formData.reason, isSubmitting, selectedCustomer]
  );

  const openDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const isButtonDisabled = (
    action: BillingToolsActionType,
    formData: { cloud?: AssetType; reason?: string; category?: string },
    formIncomplete: boolean
  ): boolean =>
    (action === "recalculate" && formData.cloud !== "amazon-web-services") ||
    (action === "issue" && !formData.category) ||
    formIncomplete ||
    invoicesLoading;

  return (
    <Stack
      spacing={3}
      sx={{
        mt: 3,
      }}
    >
      <CustomerPicker
        disabled={isSubmitting}
        value={selectedCustomer?.id}
        onChange={(_event, newCustomer) => {
          setSelectedCustomer(newCustomer ? { id: newCustomer?.objectID, name: newCustomer?.primaryDomain } : null);
        }}
        getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
          hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
        }
        getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
        TextFieldProps={{
          label: "Customer domain",
          variant: "outlined",
          required: true,
        }}
        data-cy="customer-select-textfield"
      />
      <TextField
        select
        data-cy="action"
        fullWidth
        required
        label="Action"
        margin="normal"
        variant="outlined"
        value={action}
        disabled={isSubmitting}
        onChange={(event) => {
          setAction(event.target.value as BillingToolsActionType);
        }}
      >
        {actionOptionsWithPermissions.map((action, index) => (
          <MenuItem key={index} value={action}>
            {actionTypeToName(action)}
          </MenuItem>
        ))}
      </TextField>

      {action !== "copy-billing-tables" && (
        <Box sx={{ width: "100%" }}>
          <DatePicker
            renderInput={(params) => <TextField required data-cy="invoice-month" margin="none" {...params} fullWidth />}
            label="Billing Month"
            disabled={isSubmitting}
            value={formData.invoiceMonth}
            onChange={(value) => {
              setFormData((formData) => ({ ...formData, invoiceMonth: value as DateTime<true> }));
            }}
            views={["year", "month"]}
            openTo="month"
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      )}

      <TextField
        select
        data-cy="cloud"
        fullWidth
        required
        label="Cloud"
        margin="normal"
        variant="outlined"
        value={formData.cloud ?? ""}
        disabled={isSubmitting}
        onChange={(event) => {
          setFormData((formData) => ({ ...formData, cloud: event.target.value as AssetType }));
        }}
      >
        {cloudOptionsMap[action]?.map((cloud, index) => (
          <MenuItem key={index} value={cloud}>
            {assetTypeName(cloud)}
          </MenuItem>
        )) || null}
      </TextField>

      <TextField
        data-cy="reason"
        label="Reason"
        variant="outlined"
        fullWidth
        required
        disabled={isSubmitting}
        value={formData?.reason}
        onChange={(event) => {
          setFormData((formData) => ({ ...formData, reason: event.target.value }));
        }}
      />

      {action === "issue" && (
        <>
          <TextField
            select
            data-cy="category"
            fullWidth
            required
            label="Category"
            margin="normal"
            variant="outlined"
            value={formData.category ?? ""}
            disabled={isSubmitting}
            onChange={(event) => {
              setFormData((formData) => ({ ...formData, category: event.target.value as IssueCategoryType }));
            }}
          >
            {categoryOptions.map((category, index) => (
              <MenuItem key={index} value={category}>
                {issueCategoryTypeToName(category)}
              </MenuItem>
            ))}
          </TextField>

          <FormControlLabel
            control={<Checkbox />}
            label="Is this a re-issued invoice?"
            onChange={() => {
              setFormData((formData) => ({
                ...formData,
                isReissue: !formData.isReissue,
              }));
            }}
          />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={() => {
            openDialog();
          }}
          disabled={isButtonDisabled(action, formData, formIncomplete)}
          loading={isSubmitting}
          data-cy="submit-btn"
          mixpanelEventId="billing-tools.submit"
        >
          Submit
        </LoadingButton>
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleClose={() => {
          setOpenConfirmationDialog(false);
        }}
        handleAction={handleAction}
        isSubmitting={isSubmitting}
        action={action}
        selectedCustomer={selectedCustomer}
        formData={formData}
        hasIssuedInvoices={issuedInvoicesByCloud.length > 0}
      />
    </Stack>
  );
};

export default IssueForm;
